import { useEffect, useState } from 'react';

import { SubscriptionContext } from './subscription-context';
import { useAuth } from '../auth';
import { Tables, supabase } from '../supabase';

interface Props {
  children: React.ReactNode;
}

export const SubscriptionProvider = (props: Props) => {
  const { session } = useAuth();
  const [subscription, setSubscription] = useState<Tables<'stripe_subscriptions'> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const doFetch = async () => {
      const { data, error } = await supabase
        .from('stripe_subscriptions')
        .select('*')
        .limit(1)
        .maybeSingle();

      if (error) {
        console.log('SUBSCRIPTION ERROR', error);
        setLoading(false);
        // TODO: error handling and log
        //setError('Unable to retrieve subscription status');
        return;
      }

      setLoading(false);
      setSubscription(data);
    };

    if (session) {
      doFetch(); // TODO: this is firing multiple times on page load in prod when user is logged in and has sub, but not in dev
    } else {
      setLoading(false);
      setSubscription(null);
    }
  }, [session]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        get subscriptionActive() {
          if (!subscription) {
            return false;
          }

          if (subscription.status !== 'active' && subscription.status !== 'trialing') {
            return false;
          }

          const end = new Date(subscription.current_period_end);
          const now = new Date();
          return end >= now;
        },
        setSubscription: (newSubscription) => {
          // TODO: should this remain a client state setter only or also have the server call?
          setSubscription(newSubscription);
        },
      }}
    >
      {loading ? null : props.children}
    </SubscriptionContext.Provider>
  );
};
