import { Grid, Box, ResponsiveContext } from 'grommet';

interface Props {
  left: React.ReactNode;
  middle: React.ReactNode;
  right: React.ReactNode;
}
export const ThreeColumnGrid = (props: Props) => {
  return (
    <ResponsiveContext.Consumer>
      {(responsive) =>
        responsive === 'small' ? (
          <Grid
            rows={['auto', 'auto', 'auto']}
            columns={['flex']}
            gap='medium'
            areas={[
              { name: 'left', start: [0, 0], end: [0, 0] },
              { name: 'middle', start: [0, 1], end: [0, 1] },
              { name: 'right', start: [0, 2], end: [0, 2] },
            ]}
          >
            <Box gridArea='left'>{props.left}</Box>
            <Box gridArea='middle'>{props.middle}</Box>
            <Box gridArea='right'>{props.right}</Box>
          </Grid>
        ) : (
          <Grid
            rows={['auto']}
            columns={['flex', 'flex', 'flex']}
            gap='medium'
            areas={[
              { name: 'left', start: [0, 0], end: [0, 0] },
              { name: 'middle', start: [1, 0], end: [1, 0] },
              { name: 'right', start: [2, 0], end: [2, 0] },
            ]}
          >
            <Box gridArea='left'>{props.left}</Box>
            <Box gridArea='middle'>{props.middle}</Box>
            <Box gridArea='right'>{props.right}</Box>
          </Grid>
        )
      }
    </ResponsiveContext.Consumer>
  );
};
