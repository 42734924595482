import { FormField, TextInput } from 'grommet';

interface Props {
  disabled?: boolean;
}

export const LastNameField = (props: Props) => {
  return (
    <FormField name='lastName' htmlFor='lastNameField' label='Last Name'>
      <TextInput
        id='lastNameField'
        name='lastName'
        maxLength={50}
        //autoCapitalize='none'
        placeholder='Enter last name'
        disabled={props.disabled}
      />
    </FormField>
  );
};
