import {
  Box,
  Button,
  Header,
  Layer,
  Nav,
  PageContent,
  ResponsiveContext,
  Text,
  ThemeContext,
} from 'grommet';
import { Close, Menu as MenuIcon } from 'grommet-icons';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { RoutePaths, useAuth } from '../../lib';
import { AnchorLink } from '../anchor-link';
import { Logo } from '../logo';

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const LI = styled.li`
  margin: 4px 0;
  padding: 0;
  list-style: none;
`;

export const AppHeader = () => {
  const { session, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useContext(ThemeContext);
  const isDarkTheme: boolean = (theme as any).dark;

  return (
    <Box elevation='medium' style={{ zIndex: '1', position: 'sticky', top: 0, left: 0, right: 0 }}>
      <Header background='background' justify='center'>
        <PageContent pad='medium'>
          <Box flex direction='row' align='center' justify='between'>
            <Link to='/' style={{ display: 'block', lineHeight: 0 }}>
              <Logo variant={isDarkTheme ? 'on-dark' : 'on-white'} />
            </Link>

            <ResponsiveContext.Consumer>
              {(responsive) =>
                responsive === 'small' ? (
                  <Button icon={<MenuIcon color='text' />} onClick={() => setMenuOpen(!menuOpen)} />
                ) : (
                  <>
                    <Nav direction='row' flex justify='center'>
                      <AnchorLink
                        to={RoutePaths.docs}
                        end
                        label='Docs'
                        color='text'
                        activeColor='control'
                      />
                      <AnchorLink
                        to={RoutePaths.pricing}
                        end
                        label='Pricing'
                        color='text'
                        activeColor='control'
                      />
                      <AnchorLink
                        to={RoutePaths.contact}
                        end
                        label='Contact'
                        color='text'
                        activeColor='control'
                      />
                      {/*<AnchorLink
                      to={RoutePaths.BLOG}
                       end
                      label='Blog'
                      color='text'
                      activeColor='control'
                    />*/}
                    </Nav>
                    {!session && (
                      <Nav direction='row' align='center' justify='end' width='small'>
                        <AnchorLink
                          to={RoutePaths.login}
                          end
                          label='Login'
                          color='text'
                          activeColor='control'
                        />
                        <Box background='brand' round='xsmall' pad='xsmall'>
                          <AnchorLink
                            to={RoutePaths.register}
                            end
                            label='Try For Free'
                            color='text'
                          />
                        </Box>
                      </Nav>
                    )}
                    {session && (
                      <Nav direction='row' align='center' justify='end' width='small'>
                        <AnchorLink to={RoutePaths.account} end label='Account' color='text' />
                        <Button
                          secondary
                          onClick={() => {
                            logout();
                          }}
                          label='Sign Out'
                        />
                      </Nav>
                    )}
                  </>
                )
              }
            </ResponsiveContext.Consumer>
          </Box>
        </PageContent>
      </Header>
      {menuOpen && (
        <Layer position='right' full='vertical'>
          <Box direction='row' background='brand' flex={{ shrink: 0 }} align='center'>
            <Button icon={<Close />} onClick={() => setMenuOpen(false)} />
            <Text weight='bold'>Menu</Text>
          </Box>
          <Nav pad='medium' width={'medium'}>
            <UL>
              <LI>
                <AnchorLink
                  to={RoutePaths.docs}
                  end
                  label='Docs'
                  color='text'
                  activeColor='control'
                  style={{ paddingTop: '6px', paddingBottom: '6px' }}
                  onClick={() => setMenuOpen(false)}
                />
              </LI>
              <LI>
                <AnchorLink
                  to={RoutePaths.pricing}
                  end
                  label='Pricing'
                  color='text'
                  activeColor='control'
                  style={{ paddingTop: '6px', paddingBottom: '6px' }}
                  onClick={() => setMenuOpen(false)}
                />
              </LI>
              <LI>
                <AnchorLink
                  to={RoutePaths.contact}
                  end
                  label='Contact'
                  color='text'
                  activeColor='control'
                  style={{ paddingTop: '6px', paddingBottom: '6px' }}
                  onClick={() => setMenuOpen(false)}
                />
              </LI>
              {/*<LI><AnchorLink
                        to={RoutePaths.BLOG}
                         end
                        label='Blog'
                        color='text'
                        activeColor='control'
                        style={{ paddingTop: '6px', paddingBottom: '6px' }}
                        onClick={() => setMenuOpen(false)}
                      /></LI>*/}
              {!session && (
                <LI>
                  <AnchorLink
                    to={RoutePaths.login}
                    end
                    label='Login'
                    color='text'
                    activeColor='control'
                    style={{ paddingTop: '6px', paddingBottom: '6px' }}
                    onClick={() => setMenuOpen(false)}
                  />
                </LI>
              )}
              {!session && (
                <LI>
                  <AnchorLink
                    to={RoutePaths.register}
                    end
                    label='Try For Free'
                    color='text'
                    activeColor='control'
                    style={{ paddingTop: '6px', paddingBottom: '6px' }}
                    onClick={() => setMenuOpen(false)}
                  />
                </LI>
              )}
              {session && (
                <LI>
                  <AnchorLink
                    to={RoutePaths.account}
                    end
                    label='Account'
                    color='text'
                    activeColor='control'
                    style={{ paddingTop: '6px', paddingBottom: '6px' }}
                    onClick={() => setMenuOpen(false)}
                  />
                </LI>
              )}
              {session && (
                <Button
                  secondary
                  //style={{ fontWeight: '500', paddingTop: '6px', paddingBottom: '6px' }}
                  //color='text'
                  onClick={() => {
                    setMenuOpen(false);
                    logout();
                  }}
                  label='Sign Out'
                />
              )}
            </UL>
          </Nav>
        </Layer>
      )}
      {/*<Box pad='xsmall' background={'status-critical'} direction='row' align='center' gap='xsmall'>
        <Announce />
        <Text>Website under development</Text>
      </Box>*/}
    </Box>
  );
};
