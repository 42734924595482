import { Box, Grommet, Page } from 'grommet';
import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { AppHeader, AppFooter } from './components';
import { AuthGuard, DisallowAuthGuard, RoutePaths, SubscriptionProvider } from './lib';
import { AuthProvider } from './lib/auth/AuthProvider';
import { theme } from './theme';

const BlogPage = lazy(() => import('./pages/public/blog/BlogPage'));
const ContactPage = lazy(() => import('./pages/public/contact/ContactPage'));
const DocsPage = lazy(() => import('./pages/public/docs/DocsPage'));
const HomePage = lazy(() => import('./pages/public/home/HomePage'));
const LinkExpiredPage = lazy(() => import('./pages/public/link-expired/LinkExpiredPage'));
const LoginPage = lazy(() => import('./pages/public/login/LoginPage'));
const NotFoundPage = lazy(() => import('./pages/public/not-found/NotFoundPage'));
const PricingPage = lazy(() => import('./pages/public/pricing/PricingPage'));
const PrivacyPage = lazy(() => import('./pages/public/privacy/PrivacyPage'));
const RegisterPage = lazy(() => import('./pages/public/register/RegisterPage'));
const ReleaseNotesPage = lazy(() => import('./pages/public/release-notes/ReleaseNotesPage'));
const ResendPage = lazy(() => import('./pages/public/resend/ResendPage'));
const ResetPasswordPage = lazy(() => import('./pages/public/reset-password/ResetPasswordPage'));
const TermsPage = lazy(() => import('./pages/public/terms/TermsPage'));
const VerifiedPage = lazy(() => import('./pages/public/verified/VerifiedPage'));

const AccountPage = lazy(() => import('./pages/auth/account/AccountPage'));
const ChangePasswordPage = lazy(() => import('./pages/auth/change-password/ChangePasswordPage'));

// TODO
const FullHeightLoader = () => {
  return <div></div>;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const THEME_MODE_KEY = 'theme';
function getThemeFromLocalStorage(): 'auto' | 'dark' | 'light' {
  const theme = localStorage.getItem(THEME_MODE_KEY);

  if (theme === 'dark' || theme === 'light') {
    return theme;
  }

  return 'auto';
}

function App() {
  // TODO: REMOVE WHEN LAUNCHING
  /*if (window.location.host !== 'localhost:3000' && window.location.search !== '?abc') {
    return <p>Coming soon...</p>;
  }*/
  const [themeMode, setThemeMode] = useState<'auto' | 'dark' | 'light'>(getThemeFromLocalStorage());

  return (
    <Grommet
      themeMode={themeMode}
      theme={theme}
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <BrowserRouter>
        <AuthProvider>
          <SubscriptionProvider>
            <Page flex>
              <AppHeader />
              <Box flex>
                <Suspense fallback={<FullHeightLoader />}>
                  <ScrollToTop />
                  <Routes>
                    <Route index element={<HomePage />} />
                    <Route path={RoutePaths.BLOG} element={<BlogPage />} />
                    <Route path={RoutePaths.contact} element={<ContactPage />} />
                    <Route path={RoutePaths.docs + '/*'} element={<DocsPage />} />
                    <Route path={RoutePaths.linkExpired} element={<LinkExpiredPage />} />
                    <Route path={RoutePaths.pricing} element={<PricingPage />} />
                    <Route path={RoutePaths.PRIVACY} element={<PrivacyPage />} />
                    <Route path={RoutePaths.RELEASE_NOTES} element={<ReleaseNotesPage />} />
                    <Route path={RoutePaths.terms} element={<TermsPage />} />
                    <Route path={RoutePaths.verified} element={<VerifiedPage />} />
                    <Route
                      path={RoutePaths.account}
                      element={
                        <AuthGuard>
                          <AccountPage />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.changePassword}
                      element={
                        <AuthGuard>
                          <ChangePasswordPage />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.login}
                      element={
                        <DisallowAuthGuard>
                          <LoginPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.register}
                      element={
                        <DisallowAuthGuard>
                          <RegisterPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.resend}
                      element={
                        <DisallowAuthGuard>
                          <ResendPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.reset}
                      element={
                        <DisallowAuthGuard>
                          <ResetPasswordPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route path='*' element={<NotFoundPage />} />
                  </Routes>
                </Suspense>
              </Box>
              <AppFooter
                themeMode={themeMode}
                changeThemeMode={(themeMode) => {
                  localStorage.setItem(THEME_MODE_KEY, themeMode);
                  setThemeMode(themeMode);
                }}
              />
            </Page>
          </SubscriptionProvider>
        </AuthProvider>
      </BrowserRouter>
    </Grommet>
  );
}

export default App;
