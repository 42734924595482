export const RoutePaths = {
  account: '/account',
  BLOG: '/blog',
  changePassword: '/change-password',
  contact: '/contact',

  docs: '/docs',
  DOCS_GETTING_STARTED_DOWNLOAD: 'getting-started/download', // must prefix /docs if linking from outside /docs route
  DOCS_GETTING_STARTED_CREATE_ACCOUNT: 'getting-started/create-account', // must prefix /docs if linking from outside /docs route
  DOCS_GETTING_STARTED_INITIAL_SETUP: 'getting-started/initial-setup', // must prefix /docs if linking from outside /docs route
  DOCS_GETTING_STARTED_HOME_SCREEN: 'getting-started/home-screen', // must prefix /docs if linking from outside /docs route

  DOCS_GARDENS_OVERVIEW: 'gardens', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_CREATE_GARDEN: 'gardens/create-garden', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_DELETE_GARDEN: 'gardens/delete-garden', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_RECORD_NOTE: 'gardens/record-note', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_RENAME_GARDEN: 'gardens/rename-garden', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_RESIZE_GARDEN: 'gardens/resize-garden', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_VIEW_GARDEN_DIFFERENT_DAY: 'gardens/view-garden-on-different-day', // must prefix /docs if linking from outside /docs route
  DOCS_GARDENS_VIEW_RECORDS: 'gardens/view-records', // must prefix /docs if linking from outside /docs route

  DOCS_BEDS_ADD_BED: 'beds/add-bed', // must prefix /docs if linking from outside /docs route
  DOCS_BEDS_MOVE_RESIZE: 'beds/move-or-resize', // must prefix /docs if linking from outside /docs route
  DOCS_BEDS_RECORD_NOTE: 'beds/record-note', // must prefix /docs if linking from outside /docs route
  DOCS_BEDS_REMOVE_BED: 'beds/remove-bed', // must prefix /docs if linking from outside /docs route
  DOCS_BEDS_RENAME_BED: 'beds/rename-bed', // must prefix /docs if linking from outside /docs route
  DOCS_BEDS_VIEW_RECORDS: 'beds/view-records', // must prefix /docs if linking from outside /docs route
  DOCS_BEDS_VIEW_SQUARE_RECORDS: 'beds/view-square-records', // must prefix /docs if linking from outside /docs route

  DOCS_PLANTINGS_ADD_PLANTING: 'plantings/add-planting', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_MOVE_PLANTING: 'plantings/move-planting', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_RECORD_HARVEST: 'plantings/record-harvest', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_RECORD_NOTE: 'plantings/record-note', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_REMOVE_PLANTING: 'plantings/remove-planting', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_REMOVE_ALL_BED_PLANTINGS: 'plantings/remove-bed-plantings', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_REMOVE_ALL_SQUARE_PLANTINGS: 'plantings/remove-square-plantings', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_VIEW_RECORDS: 'plantings/view-records', // must prefix /docs if linking from outside /docs route
  DOCS_PLANTINGS_VIEW_VARIETY_INFO: 'plantings/view-variety-information', // must prefix /docs if linking from outside /docs route

  DOCS_PLANTCATALOG_OVERVIEW: 'plant-catalog', // must prefix /docs if linking from outside /docs route

  DOCS_SETTINGS_OVERVIEW: 'settings', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_THEME: 'settings/theme', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_PLANT_PROGRESS: 'settings/show-plant-progress', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_GRID_LINES: 'settings/show-grid-lines', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_COUNTRY: 'settings/country', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_ZONE: 'settings/zone', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_FROST_DATES: 'settings/custom-frost-dates', // must prefix /docs if linking from outside /docs route
  DOCS_SETTINGS_UNITS: 'settings/use-metric-units', // must prefix /docs if linking from outside /docs route

  DOCS_ACCOUNT_OVERVIEW: 'account', // must prefix /docs if linking from outside /docs route
  DOCS_ACCOUNT_CHANGE_PASSWORD: 'account/change-password', // must prefix /docs if linking from outside /docs route

  DOCS_SUPPORT_REPORT_BUG: 'support/report-bug', // must prefix /docs if linking from outside /docs route
  DOCS_SUPPORT_REQUEST_FEATURE: 'support/request-feature', // must prefix /docs if linking from outside /docs route
  DOCS_SUPPORT_REQUEST_PLANT: 'support/request-plant-or-variety', // must prefix /docs if linking from outside /docs route

  linkExpired: '/link-expired',
  login: '/login',
  pricing: '/pricing',
  PRIVACY: '/privacy-policy', // TODO: build page
  register: '/register',
  RELEASE_NOTES: '/release-notes',
  resend: '/resend',
  reset: '/reset',
  terms: '/terms-and-conditions', //put disclaimer inside terms and conditions
  verified: '/verified',
} as const;

export const ExternalLinks = {
  GOOGLE_PLAY: '#', // TODO
  APPLE_APPSTORE: '#', // TODO
};
