import { ThemeType } from 'grommet';
import { deepMerge } from 'grommet/utils';

export const colors = {
  brand: '#158f2a',
  'background-back': '#EDEDED',
  'background-front': '#FFFFFF',
  'background-contrast': '#333333',
  background: {
    light: '#FFFFFF',
    dark: '#1B1B1B',
  },
  text: { dark: 'white', light: '#494949' },
  'text-strong': '#000000',
  'text-weak': '#555555',
  'text-xweak': '#666666',
  //icon: '#666666',
  border: {
    light: '#DDDDDD',
    dark: '#444444',
  },
  control: {
    light: 'brand',
    dark: 'accent-1', // Slightly brighter for dark mode contrast
  },
  focus: 'brand',
  placeholder: '#AAAAAA',
  active: 'rgba(221, 221, 221, 0.5)',
  'active-background': 'background-contrast',
  'active-text': 'text-strong',
  selected: 'brand',
  'selected-background': 'brand',
  'selected-text': 'text-strong',
  'status-critical': '#EB0000',
  'status-error': '#B30000',
  'status-warning': '#ffa600',
  'status-ok': '#009E67',
  'status-unknown': '#919191',
  'status-disabled': '#CCCCCC',
  'graph-0': 'accent-1',
  'graph-1': 'neutral-1',
  'graph-2': 'neutral-2',
  'graph-3': 'neutral-3',
  'graph-4': 'neutral-4',
  'accent-1': '#1DB954',
  'accent-2': '#FD6FFF',
  'accent-3': '#81FCED',
  'accent-4': '#FFCA58',
  'neutral-1': '#00873D',
  'neutral-2': '#3D138D',
  'neutral-3': '#00739D',
  'neutral-4': '#A2423D',
  'light-1': '#F8F8F8',
  'light-2': '#F2F2F2',
  'light-3': '#EDEDED',
  'light-4': '#DADADA',
  'light-5': '#DADADA',
  'light-6': '#DADADA',
  'dark-1': '#333333',
  'dark-2': '#555555',
  'dark-3': '#777777',
  'dark-4': '#999999',
  'dark-5': '#999999',
  'dark-6': '#999999',
  black: '#000000',
  white: '#FFFFFF',
};

export const theme: ThemeType = deepMerge({
  global: {
    colors,
    font: {
      family: 'Open Sans, sans-serif',
      size: '16px',
      height: '1.5',
    },
    input: {
      font: {
        weight: 500,
      },
    },
    elevation: {
      light: {
        small: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        medium: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        large: '0px 10px 20px rgba(0, 0, 0, 0.2)',
      },
      dark: {
        small: '0px 1px 3px rgba(255, 255, 255, 0.2)',
        medium: '0px 3px 6px rgba(255, 255, 255, 0.2)',
        large: '0px 10px 20px rgba(255, 255, 255, 0.2)',
      },
    },
  },
  anchor: {
    fontWeight: 500,
  },
  button: {
    border: {
      radius: '4px',
    },
    padding: {
      vertical: '8px',
      horizontal: '16px',
    },
    primary: {
      color: 'brand', // Your primary color
    },
  },
  formField: {
    border: {
      color: 'border',
      side: 'all',
    },
    round: 'xsmall',
    label: { margin: { horizontal: '0px', vertical: 'xsmall' } },
    focus: {
      border: {
        color: 'brand', // Match primary color
      },
    },
  },
  heading: {
    level: {
      '1': {
        small: {
          size: '28px',
        },
      },
    },
  },
  layer: {
    background: {
      light: '#FFFFFF',
      dark: '#222222',
    },
  },
  notification: {
    toast: {
      time: 10000,
    },
    info: {
      background: 'neutral-3',
      color: 'white',
    },
  },
  paragraph: {
    medium: {
      size: '16px',
      height: '20px',
    },
  },
  text: {
    medium: {
      size: '16px',
      height: '20px',
    },
  },
});
