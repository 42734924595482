import { Grid, Box, ResponsiveContext } from 'grommet';

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
  flip?: boolean;
  keepLeftOnBottom?: boolean;
}
export const OneToTwoColumnGrid = (props: Props) => {
  return (
    <ResponsiveContext.Consumer>
      {(responsive) =>
        responsive === 'small' ? (
          <Grid
            rows={['auto', 'auto']}
            columns={['flex']}
            gap='small'
            areas={[
              { name: props.keepLeftOnBottom ? 'right' : 'left', start: [0, 0], end: [0, 0] },
              { name: props.keepLeftOnBottom ? 'left' : 'right', start: [0, 1], end: [0, 1] },
            ]}
          >
            <Box gridArea='left'>{props.left}</Box>
            <Box gridArea='right'>{props.right}</Box>
          </Grid>
        ) : (
          <Grid
            rows={['auto']}
            columns={props.flip ? ['flex', 'auto'] : ['auto', 'flex']}
            gap='medium'
            areas={
              props.flip
                ? [
                    { name: 'right', start: [0, 0], end: [0, 0] },
                    { name: 'left', start: [1, 0], end: [1, 0] },
                  ]
                : [
                    { name: 'left', start: [0, 0], end: [0, 0] },
                    { name: 'right', start: [1, 0], end: [1, 0] },
                  ]
            }
          >
            <Box gridArea='left'>{props.left}</Box>
            <Box gridArea='right'>{props.right}</Box>
          </Grid>
        )
      }
    </ResponsiveContext.Consumer>
  );
};
