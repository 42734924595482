import styled from 'styled-components';

export const UnorderedList = styled.ul`
  margin: 0.5rem 0 1rem 0;
  padding: 0 0 0 1.5rem;
`;

export const OrderedList = styled.ol`
  margin: 0.5rem 0 1rem 0;
  padding: 0 0 0 1.5rem;
`;

export const ListItem = styled.li`
  margin: 0.5rem 0;
  line-height: 1.25rem;
`;
