import {
  Anchor,
  Box,
  Button,
  DropButton,
  Footer,
  Grommet,
  PageContent,
  Text,
  ThemeContext,
} from 'grommet';
import { Facebook, Instagram, Moon, Sun, X, Youtube } from 'grommet-icons';
import { useContext } from 'react';

import { ExternalLinks, RoutePaths } from '../../lib';
import { AnchorLink } from '../anchor-link';
import { AppStoreLogo, GooglePlayLogo } from '../app-stores';
import { Logo } from '../logo';
import { OneThirdTwoThirdsColumnGrid } from '../responsive-grid';

interface Props {
  themeMode: 'auto' | 'dark' | 'light';
  changeThemeMode: (themeMode: 'auto' | 'dark' | 'light') => void;
}

export const AppFooter = (props: Props) => {
  const theme = useContext(ThemeContext);
  const isDarkTheme: boolean = (theme as any).dark;

  return (
    <Footer background='brand' justify='center' style={{ fontSize: 'small' }}>
      <PageContent pad='medium'>
        <OneThirdTwoThirdsColumnGrid
          left={
            <Box flex gap='small'>
              <Logo variant='on-green' />
              <Box direction='row' gap={'medium'}>
                <AnchorLink to={'https://x.com/CroppaApp'} target='_blank'>
                  <X />
                </AnchorLink>
                <AnchorLink to={'https://www.youtube.com/@Croppa'} target='_blank'>
                  <Youtube />
                </AnchorLink>
                <AnchorLink to={'https://www.instagram.com/croppa.app'} target='_blank'>
                  <Instagram />
                </AnchorLink>
                <AnchorLink to={'https://www.facebook.com/CroppaApp'} target='_blank'>
                  <Facebook />
                </AnchorLink>
              </Box>
              <Box direction='row' wrap style={{ gap: '8px' }}>
                <Anchor href={ExternalLinks.APPLE_APPSTORE}>
                  <AppStoreLogo />
                </Anchor>
                <Anchor href={ExternalLinks.GOOGLE_PLAY}>
                  <GooglePlayLogo />
                </Anchor>
              </Box>
            </Box>
          }
          right={
            <Box direction='row'>
              <Box flex>
                <Text margin={{ bottom: 'small' }}>Resources</Text>
                <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                  {/*<li>
                  <AnchorLink color={'white'} to={RoutePaths.BLOG}>
                    Blog
                  </AnchorLink>
                </li>*/}
                  <li>
                    <AnchorLink color={'white'} to={RoutePaths.contact}>
                      Contact
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink color={'white'} to={RoutePaths.docs}>
                      Documentation
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink color={'white'} to={RoutePaths.pricing}>
                      Pricing
                    </AnchorLink>
                  </li>
                  {/*<li>
                    <AnchorLink color={'white'} to={RoutePaths.RELEASE_NOTES}>
                      Release Notes
                    </AnchorLink>
                  </li>*/}
                </ul>
              </Box>
              <Box flex>
                <Text margin={{ bottom: 'small' }}>Legal</Text>
                <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                  <li>
                    <AnchorLink color={'white'} to={RoutePaths.PRIVACY}>
                      Privacy Policy
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink color={'white'} to={RoutePaths.terms}>
                      Terms & Conditions
                    </AnchorLink>
                  </li>
                </ul>
              </Box>
            </Box>
          }
        />
        <Box
          direction='row'
          align='center'
          justify='between'
          margin={{ top: 'medium' }}
          pad={{ top: 'medium' }}
          border={{ side: 'top', size: '1px', style: 'solid', color: 'white' }}
        >
          <Box>
            <Text size='xsmall'>© {new Date().getFullYear()} Croppa</Text>
          </Box>
          <DropButton
            icon={isDarkTheme ? <Moon /> : <Sun />}
            dropAlign={{ bottom: 'top', right: 'right' }}
            dropContent={
              <Grommet theme={theme} themeMode={props.themeMode}>
                <Box width='small' pad='small' gap='small'>
                  <Text>Theme</Text>
                  <Button
                    label='System'
                    primary={props.themeMode === 'auto'}
                    onClick={() => props.changeThemeMode('auto')}
                  />
                  <Button
                    label='Light'
                    primary={props.themeMode === 'light'}
                    onClick={() => props.changeThemeMode('light')}
                  />
                  <Button
                    label='Dark'
                    primary={props.themeMode === 'dark'}
                    onClick={() => props.changeThemeMode('dark')}
                  />
                </Box>
              </Grommet>
            }
          />
        </Box>
      </PageContent>
    </Footer>
  );
};
